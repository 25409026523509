import React from 'react';
import { graphql } from 'gatsby';
import ListingPage from '../components/ListingPage';

export default ({ data: { cover, destinations } }) => (
  <ListingPage
    cover={cover}
    list={destinations}
    title="Ziele"
    subtitle="Die schönsten Orte dieser Welt"
  />
);

export const query = graphql`
  query {
    cover: file(relativePath: { eq: "compass.jpeg" }) {
      childImageSharp {
        fluid(quality: 90, maxWidth: 1024) {
          ...GatsbyImageSharpFluid_withWebp
        }
      }
    }
    destinations: allSanityGoal {
      totalCount
      edges {
        node {
          title
          _rawExcerpt
          price
          mainImage {
            asset {
              fluid(maxWidth: 2048) {
                ...GatsbySanityImageFluid
              }
            }
          }
          slug {
            current
          }
        }
      }
    }
  }
`;
